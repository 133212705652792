import { acceptHMRUpdate, defineStore } from 'pinia'

const MESSAGES = {
  'Failed to fetch ()': 'Cannot communicate with service',
}

const unknownToString = (m: unknown): string => {
  if (typeof m === 'string') {
    return m
  }

  if (m instanceof Error) {
    return m.message
  }

  if (typeof m === 'object') {
    return JSON.stringify(m)
  }

  return `${m}`
}

const formatErrorMessage = (message: unknown): string => {
  const m = unknownToString(message)

  return MESSAGES[m] || m
}

export const useAppHealth = defineStore('app-health', {
  state: () => {
    return {
      apiWorkerAvailable: true,
      apiWorkerMessage: '',
      authWorkerAvailable: true,
      authWorkerMessage: '',
      consoleApiAvailable: true,
      consoleApiMessage: '',
    }
  },

  actions: {
    checkAll() {
      this.checkAuthWorkerStatus()
      this.checkApiWorkerStatus()
      this.checkConsoleApiStatus()
    },

    async checkApiWorkerStatus() {
      const { $datadog } = useNuxtApp()
      const api = useWorkersApi()

      try {
        await api.fetchPublic('/')
        await api.fetchPublic('/', { method: 'POST' })

        this.$patch({
          apiWorkerAvailable: true,
          apiWorkerMessage: '',
        })
      } catch (error) {
        $datadog.addError('api worker unavailable', error)
        this.$patch({
          apiWorkerAvailable: false,
          apiWorkerMessage: formatErrorMessage(error.data || error),
        })
      }
    },

    async checkAuthWorkerStatus() {
      const { $auth, $datadog } = useNuxtApp()
      try {
        await $auth.apiCheck()

        this.$patch({
          authWorkerAvailable: true,
          authWorkerMessage: '',
        })
      } catch (error) {
        $datadog.addError('auth api unavailable', error)

        this.$patch({
          authWorkerAvailable: false,
          authWorkerMessage: formatErrorMessage(error.data || error),
        })
      }
    },

    async checkConsoleApiStatus() {
      const { $datadog } = useNuxtApp()
      const api = useWorkersApi()

      try {
        await api.fetchPublic('/info')

        this.$patch({
          consoleApiAvailable: true,
          consoleApiMessage: '',
        })
      } catch (error) {
        $datadog.addError('console api unavailable', error)
        this.$patch({
          consoleApiAvailable: false,
          consoleApiMessage: formatErrorMessage(error.data || error),
        })
      }
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAppHealth, import.meta.hot))
